import React, { useEffect, useState } from 'react';
import Toggle from './Toggle';
import { Toast } from '../Alert';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';

const Settings = () => {
    const [formData, setFormData] = useState({
        first_name: false,
        last_name: false,
        age: false,
        gender: false,
        status: false,
        dependents: false,
        education: false,
        property: false,
        appraised_value: false,
        self_employed: false,
        work_experience: false,
        applicant_income: false,
        co_applicant_income: false,
        loan_type: false,
        loan_rate: false,
        loan_history: false,
        loan_amount: false,
        loan_period: false,
        payslip: false,
        utility_payment: false,
        personal: '',
        business: '',
        mortgage: '',
    });
    const navigate = useNavigate();

    const handleToggleChange = (toggleName: string, isChecked: boolean) => {
        setFormData((prevData) => ({
            ...prevData,
            [toggleName]: isChecked,
        }));
    };

    const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }
        ));
    };

    const sendData = async (data: any) => {
        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                navigate('/auth/Login');
                return;
            }


            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
                Accept: 'application/json',
            };

            const requestData = data;

            const response = await axios.post('https://creditdecisionbackend.lcfordevelopment.com/api/clients/', requestData, { headers });

            if (response.status === 201) {
                Toast('Analysing user data', 'success');

            } else {
                console.log('Error', response)
                Toast('An error occured try again', 'error');
            }
        } catch (error) {
            console.error(error)
            if (axios.isAxiosError(error)) {
                handleRequestError(error);
            } else {
                console.error('Non-Axios Error:', error);
                Toast('Reload your page.', 'warning');
            }
        }
    }

    const handleRequestError = (error: AxiosError) => {

        if (error.response) {
            console.error('Server Error:', error.response.data);
            console.error('Status:', error.response.status);

            if (error.response.status === 400) {
                Toast('Unknow error', 'error');
            }
            if (error.response.status === 401) {
                Toast('Unauthorized', 'error');
                navigate('/auth/Login');
                return;
            }
            if (error.response.status === 403) {
                Toast('Unauthorized', 'error');
                navigate('/auth/Login');
                return;
            }
        } else if (error.request) {
            Toast("An Error occurred", 'warning')
            console.error('No response received:', error.request);
        } else {
            Toast("An Error occurred", 'warning')
            console.error('Error:', error.message);
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log('data:', formData);
        sendData(formData);
    };

    useEffect(() => {
        const displayForm = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                if (!token) {
                    navigate('/auth/Login');
                    return;
                }


                const headers = {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                    Accept: 'application/json',
                };


                const response = await axios.post('https://creditdecisionbackend.lcfordevelopment.com/api/form_input/', {}, { headers });

                if (response.status === 200) {
                    setFormData(response.data)
                } else {
                    console.log('Error', response)
                    Toast('An error occured try again', 'error');
                }
            } catch (error) {
                Toast('Network Error. Please try again.', 'warning');
                if (axios.isAxiosError(error)) {
                    handleRequestError(error);
                } else {
                    console.error('Non-Axios Error:', error);
                    Toast('Reload your page.', 'warning');
                }
            }
        }
        displayForm()
    }, [])

    return (
        <div>
            <h1>Settings</h1>
            <p>Configure Registrations fields</p>
            <form onSubmit={handleSubmit}>
                <div className='wrapped'>
                    <div className='card'>
                        <h2>Personnal Informations</h2>
                        <label className='flex'>
                            Name: <Toggle bchecked={formData.first_name} onChange={(isChecked) => handleToggleChange('first_name', isChecked)} />
                        </label>
                        <label className='flex'>
                            Surname: <Toggle bchecked={formData.last_name} onChange={(isChecked) => handleToggleChange('last_name', isChecked)} />
                        </label>
                        <label className='flex'>
                            Age: <Toggle bchecked={formData.age} onChange={(isChecked) => handleToggleChange('age', isChecked)} />
                        </label>
                        <label className='flex'>
                            Gender: <Toggle bchecked={formData.gender} onChange={(isChecked) => handleToggleChange('gender', isChecked)} />
                        </label>
                        <label className='flex'>
                            Status: <Toggle bchecked={formData.status} onChange={(isChecked) => handleToggleChange('status', isChecked)} />
                        </label>
                        <label className='flex'>
                            Dependents: <Toggle bchecked={formData.dependents} onChange={(isChecked) => handleToggleChange('dependents', isChecked)} />
                        </label>
                        <label className='flex'>
                            Education: <Toggle bchecked={formData.education} onChange={(isChecked) => handleToggleChange('education', isChecked)} />
                        </label>
                        <label className='flex'>
                            Property Area: <Toggle bchecked={formData.property} onChange={(isChecked) => handleToggleChange('property', isChecked)} />
                        </label>
                        <label className='flex'>
                            Appraised Value: <Toggle bchecked={formData.appraised_value} onChange={(isChecked) => handleToggleChange('appraised_value', isChecked)} />
                        </label>
                    </div>
                    <div className='card'>
                        <h2>Work Related Informations</h2>
                        <label className='flex'>
                            Self employed : <Toggle bchecked={formData.self_employed} onChange={(isChecked) => handleToggleChange('self_employed', isChecked)} />
                        </label>
                        <label className='flex'>
                            Work Experience: <Toggle bchecked={formData.work_experience} onChange={(isChecked) => handleToggleChange('work_experience', isChecked)} />
                        </label>
                    </div>
                    <div className='card'>
                        <h2>Loan related Informations</h2>
                        <label className='flex'>
                            Applicant Income : <Toggle bchecked={formData.applicant_income} onChange={(isChecked) => handleToggleChange('applicant_income', isChecked)} />
                        </label>
                        <label className='flex'>
                            Co-Applicant Income: <Toggle bchecked={formData.co_applicant_income} onChange={(isChecked) => handleToggleChange('co_applicant_income', isChecked)} />
                        </label>
                        <label className='flex'>
                            Loan Types: <Toggle bchecked={formData.loan_type} onChange={(isChecked) => handleToggleChange('loan_type', isChecked)} />
                        </label>
                        <label className='flex'>
                            Loan Rates: <Toggle bchecked={formData.loan_rate} onChange={(isChecked) => handleToggleChange('loan_rate', isChecked)} />
                        </label>
                        <label className='flex'>
                            Loan History: <Toggle bchecked={formData.loan_history} onChange={(isChecked) => handleToggleChange('loan_history', isChecked)} />
                        </label>
                        <label className='flex'>
                            Loan Amount: <Toggle bchecked={formData.loan_amount} onChange={(isChecked) => handleToggleChange('loan_amount', isChecked)} />
                        </label>
                        <label className='flex'>
                            Loan Period: <Toggle bchecked={formData.loan_period} onChange={(isChecked) => handleToggleChange('loan_period', isChecked)} />
                        </label>
                        <label className='flex'>
                            Payslip: <Toggle bchecked={formData.payslip} onChange={(isChecked) => handleToggleChange('payslip', isChecked)} />
                        </label>
                        <label className='flex'>
                            Utility Payment: <Toggle bchecked={formData.utility_payment} onChange={(isChecked) => handleToggleChange('utility_payment', isChecked)} />
                        </label>
                    </div>
                    <div className='card'>
                        <h2>Loan Types Rates</h2>
                        <label className='flex'>
                            Personal: <input className='miniinput' type='number' name='personal' value={formData.personal} onChange={handleRateChange} />
                        </label>
                        <label className='flex'>
                            Business: <input className='miniinput' type='number' name='business' value={formData.business} onChange={handleRateChange} />
                        </label>
                        <label className='flex'>
                            Mortgage: <input className='miniinput' type='number' name='mortgage' value={formData.mortgage} onChange={handleRateChange} />
                        </label>
                    </div>
                </div>
                <button className='btn1' type="submit">Submit</button>
            </form>
        </div>
    );
};

export default Settings;
