import React, { useState } from 'react';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { Modal, ContactModal } from './Alert';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

interface TimelineProps {
    timeline: (value: boolean) => void; // Function to send boolean to parent
}

const Custum: React.FC<TimelineProps> = ({ timeline }) => {
    const [open, setOpen] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [timelineIcon, setTimelineIcon] = useState(false);
    const [cookies, , removeCookie] = useCookies(['admin']);
    const navigate = useNavigate();


    // const handleDayNightMode = () => {
    //     // Logic for toggling day/night mode
    // };

    const handleLogout = async () => {
        const logout = await Modal('Are you sure you want to logout ?', 'question');
        if (logout.isConfirmed === true) {
            localStorage.removeItem('accessToken');
            // Redirect to the login page
            navigate('/auth/Login');
            removeCookie('admin', { path: '/' });
        }
    };

    const handleQuestion = async () => {
        const result = await ContactModal();

        if (result.isConfirmed) {
            const { message, email } = result.value;
            console.log('Submitted Message:', message);
            console.log('Submitted Email:', email);
        }
    };



    const handleFullScreen = () => {
        const elem = document.documentElement;

        if (!fullScreen) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            }
            setFullScreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
            setFullScreen(false);
        }
    };

    const handleTimeline = () => {
        timeline(true);
        setTimelineIcon(true);
    };

    const handleReg = () => {
        timeline(false);
        setTimelineIcon(false);
    };

    const admin = cookies.admin;

    const handleDashboard = () => {
        navigate('/admin/AdminDash');
    }
    return (
        <>
            <SpeedDial
                ariaLabel="Actions"
                icon={<SpeedDialIcon />}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                direction={'left'}
                sx={{ position: 'fixed', bottom: 15, right: 15 }}
            >
                {/* <SpeedDialAction
                    tooltipTitle="theme"
                    onClick={handleDayNightMode}
                    icon={<i className="fa-solid fa-circle-half-stroke"></i>}
                    className='speedDial'
                /> */}
                <SpeedDialAction
                    tooltipTitle="Logout"
                    onClick={handleLogout}
                    icon={<i className="fa-solid fa-right-from-bracket"></i>}
                />
                <SpeedDialAction
                    tooltipTitle="Question"
                    onClick={handleQuestion}
                    icon={<i className="fa-solid fa-question"></i>}
                />
                <SpeedDialAction
                    tooltipTitle="Fullscreen"
                    onClick={handleFullScreen}
                    icon={fullScreen ? <i className="fa-solid fa-compress"></i> : <i className="fa-solid fa-maximize"></i>}
                />
                {timelineIcon ? (
                    <SpeedDialAction
                        tooltipTitle="Registration"
                        onClick={handleReg}
                        icon={<i className="fa-solid fa-pen-to-square"></i>}
                    />
                ) : (
                    <SpeedDialAction
                        tooltipTitle="Timeline"
                        onClick={handleTimeline}
                        icon={<i className="fa-solid fa-timeline"></i>}
                    />
                )}
                {admin && (
                    <SpeedDialAction
                        tooltipTitle="Admin Dasboard"
                        onClick={handleDashboard}
                        icon={<i className="fa-solid fa-user-shield"></i>}
                    />
                )}
            </SpeedDial>
        </>
    );
};

export default Custum;
