import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { Toast } from '../Alert';
import { useCookies } from 'react-cookie';


function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="#">
            Smart Credit Decision AI
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const schema = yup.object().shape({
    email: yup.string().required('*** Email is required ***').email('*** Invalid email ***'),
    password: yup
        .string()
        .required('Password is required')
        .matches(/[a-z]/, '*** Password must contain at least one lowercase letter ***')
        .matches(/[A-Z]/, '*** Password must contain at least one uppercase letter ***')
        .matches(/\d/, '*** Password must contain at least one number ***')
        .min(6, '*** Password must be at least 6 characters ***'),
});


const defaultTheme = createTheme();

export default function SignInSide() {
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const [logData, setLogData] = useState('')
    const [cookies, setCookie] = useCookies(['admin']);
    const navigate = useNavigate();

    const [rememberMe, setRememberMe] = useState(false);
    // useEffect(() => {
    //     const token = localStorage.getItem('accessToken');
    //     if (token) {
    //         navigate('/Home');
    //     }
    // }, [navigate]);
    const onSubmit = async (data: any) => {
        try {
            const token = localStorage.getItem('accessToken');
            //   if (!token) {
            //     navigate('/login');
            //     return;
            //   }
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
            };


            const requestData = {
                password: data.password,
                username: data.email,
            };

            setLogData(requestData.username)
            const response = await axios.post('https://creditdecisionbackend.lcfordevelopment.com/api/login/', requestData, { headers });




            if (response.status === 200) {
                if (response.data.message !== 'admin') {
                    navigate('/Home');
                } else {
                    setCookie('admin', 'admin', { path: '/', maxAge: 3600 * 24 * 7 });
                    navigate('/Home');
                }

                const token = response.data.token;

                if (rememberMe) {
                    localStorage.setItem('accessToken', token);
                } else {
                    const now = new Date().getTime();
                    const oneDayInMs = 48 * 60 * 60 * 1000;
                    const expiryTime = now + oneDayInMs;
                    localStorage.setItem('accessToken', token);
                    localStorage.setItem('tokenExpiry', String(expiryTime));
                }
            } else {
                Toast(`${response.data.message}`, 'error');
            }
        } catch (error) {
            Toast("Network Error please try again", 'warning')
            if (axios.isAxiosError(error)) {
                handleRequestError(error);
            } else {
                console.error('Non-Axios Error:', error);
                Toast("Reload your page ", 'warning')
            }
        }
    };

    const handleRequestError = (error: AxiosError) => {
        if (error.response) {
            const { status, data } = error.response;

            if (status === 401) {
                Toast('Unauthorized', 'error');
            } else {
                if (data && typeof data === 'object' && Object.keys(data).length > 0 && 'non_field_errors' in data) {
                    const logError = (data as { non_field_errors: string[] }).non_field_errors;

                    if (logError.includes('Unable to log in with provided credentials.')) {
                        const logErrors = `No account with ${logData}`;
                        Toast(logErrors, 'warning');
                    } else {
                        Toast(logError.join(', '), 'warning');
                    }
                }
            }
        } else if (error.request) {
            console.error('No response received:', error.request);
        } else {
            console.error('Error:', error.message);
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(/01.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: '#071d3a' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                // name="email"
                                autoComplete="email"
                                autoFocus
                                {...register('email')}
                            />
                            {errors.email &&
                                <span className="validError">
                                    {errors.email.message}
                                </span>
                            }
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                // name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                {...register('password')}
                            />
                            {errors.password &&
                                <span className="validError">
                                    {errors.password.message}
                                </span>
                            }<br></br>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rememberMe}
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3, mb: 2, width: '50%', bgcolor: '#0C356A',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    display: 'block',
                                }}
                            >
                                Sign In
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    {"Don't have an account?"}
                                    <Link href="#" variant="body2">{" Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}