import React, { useState } from 'react';
import { Table, Badge, Input } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { TableRowSelection } from 'antd/es/table/interface';
import { Toast } from '../Alert';


interface DataType {
    key: React.ReactNode;
    client: number;
    first_name: string;
    last_name: string;
    machine_answer:  true | false; 
    minidata: string;
    amount: GLfloat;
}

interface AppProps {
    data: DataType[];
}

const DashTable: React.FC<AppProps> = ({ data }) => {

    const [filteredData, setFilteredData] = useState<DataType[]>([]);

    const columns: ColumnsType<DataType> = [
        {
            title: 'ID',
            dataIndex: 'client',
            key: 'client',
            fixed: 'left',
            width: 30,
        },
        {
            title: 'Name',
            dataIndex: 'first_name',
            key: 'first_name',
            fixed: 'left',
            width: 60,
        },
        {
            title: 'Surname',
            dataIndex: 'last_name',
            key: 'last_name',
            fixed: 'left',
            width: 60,
        },
        {
            title: 'Loan',
            dataIndex: 'amount',
            key: 'amount',
            width: 30,
        },
        {
            title: 'Machine Answer',
            dataIndex: 'machine_answer',
            key: 'machine_answer',
            width: 50,

            render: (value) => (
                <Badge
                    status={value === true ? 'success' : 'error'}
                    text={value === true ? 'approuved' : 'rejected'}
                />
            ),
            fixed: 'right',
        }
    ];


    const rowSelection: TableRowSelection<DataType> = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows);
        },
    };


    const handleSearch = (value: string) => {
        const lowercasedValue = value.toLowerCase();
        const filtered = data.filter((item) => {
          let minidata: string = '';
      
          if (item.machine_answer === true) {
            minidata = 'approuved';
          } else {
            minidata = 'rejected';
          }
      
          const found =
            String(item.client).includes(lowercasedValue) ||
            item.first_name.toLowerCase().includes(lowercasedValue) ||
            item.last_name.toLowerCase().includes(lowercasedValue) ||
            String(minidata).includes(lowercasedValue) ||
            String(item.amount).includes(lowercasedValue);
      
          return found;
        });
      
        setFilteredData(filtered);
      
        if (value && filtered.length === 0) {
          Toast(`No results found for '${value}'`, 'info');
        }
      };
      

    return (
        <>
            <Input.Search
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                style={{ width: 250, marginBottom: 16 }}
            />
            <Table
                columns={columns}
                rowSelection={{ ...rowSelection }}
                dataSource={filteredData.length > 0 ? filteredData : data}
                scroll={{ y: 350 }}

                pagination={{ pageSize: 6, total: data.length }}
            />
        </>
    );
};

export default DashTable;
