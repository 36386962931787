import Swal from 'sweetalert2';

export const Toast = (message: string, type: 'success' | 'question' | 'error' | 'warning' | 'info' = 'success') => {
    Swal.fire({
        toast: true,
        position: "top-end",
        icon: type,
        title: message,
        showConfirmButton: false,
        timer: 3000, // Adjust the duration as needed
    });
};
export const Modal = (message: string, type: 'success' | 'error' | 'question' | 'warning' | 'info' = 'success') => {
    return Swal.fire({
        icon: type,
        title: message,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: (result) => {
            return result;
        }
    });
};


export const ContactModal = () => {
    return Swal.fire({
        title: 'A problem? Send us a message.<br> We will answer as soon as possible.',
        html:
            '<input id="email" class="swal2-input" placeholder="Enter your email" type="email">' +
            '<textarea id="textarea" class="swal2-textarea" placeholder="Enter your message here..." style="resize:none;"></textarea>',
        showCancelButton: true,
        confirmButtonText: 'Submit',
        preConfirm: () => {
            const popup = Swal.getPopup();
            if (!popup) return null;

            const textarea = popup.querySelector('#textarea') as HTMLTextAreaElement | null;
            const emailInput = popup.querySelector('#email') as HTMLInputElement | null;

            if (!textarea || !emailInput) return null;

            return {
                message: textarea.value,
                email: emailInput.value,
            };
        },
    });
};

