import React from 'react';
import { Link } from 'react-router-dom';
import AnimatedBackground from './AnimatedBackground';

const NotFound404: React.FC = () => {
  return (
    <>
      <div className="not-found-content">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-text">Oops! Page not found.</p>
        <p className="not-found-text">The page you are looking for might have been removed or doesn't exist.</p>
        <Link to="/Home/" className="not-found-link">Go back to the Home Page</Link>
      </div>
      <AnimatedBackground />
    </>
  )
}

export default NotFound404