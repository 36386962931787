import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';

interface ChartProps {
  lineData: any[];
  pieData: { name: string; value: number; }[];
  pieData2: { name: string; value: number; }[];
}

const CustomChart: React.FC<ChartProps> = ({ lineData, pieData, pieData2 }) => {
  const pieColors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#FF6666', '#012b63'];

  return (
    <div>
      <h1>Charts</h1>

      <div style={{ marginLeft: 20 }}>
        <h3>Loan Amount</h3>
        <LineChart width={900} height={450} data={lineData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="value" stroke="#012b63" activeDot={{ r: 6 }} />
        </LineChart>
      </div>



      <div style={{ display: 'flex' }}>

        <div style={{ flex: 1 }}>
          <h3>Applicant per Status</h3>
          <PieChart width={400} height={400}>
            <Pie
              data={pieData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={150}
              fill="#8884d8"
              label
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </div>


        <div style={{ flex: 1 }}>
          <h3>Applicant per Gender</h3>
          <PieChart width={400} height={400}>
            <Pie
              data={pieData2}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={150}
              fill="#8884d8"
              label
            >
              {pieData2.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </div>
      </div>

    </div>
  );
};

export default CustomChart;
