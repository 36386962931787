import React, { useState, useEffect } from 'react'
import { Card } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Loader from './Loader';
import { Toast } from './Alert';
import axios, { AxiosError } from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import RiskAssessment from './RiskAssessment';



const AiOutput: React.FC = () => {
  const [loader, setLoader] = useState(false)
  const [aiResponse, setAiResponse] = useState('')
  const [cookies, , removeCookie] = useCookies(['userID']);
  const navigate = useNavigate();
  const [requestMade, setRequestMade] = useState(false)
  const [aiResponseStatus, setAiResponseStatus] = useState(false)
  const [certaintly, setCertaintly] = useState(0)
  const [factors, setFactors] = useState<string[]>([])
  const [riskScore, setRiskScore] = useState(0)


  loader && Toast('Wait a few seconds', 'info')

  const handleRequestError = (error: AxiosError) => {

    if (error.response) {
      console.error('Server Error:', error.response.data);
      console.error('Status:', error.response.status);

      if (error.response.status === 400) {
        Toast('Unknow error', 'error');
      }
      else if (error.response.status === 401) {
        Toast('Unauthorized', 'error');
        navigate('/auth/Login');
      }
      else if (error.response.status === 403) {
        Toast('Unauthorized', 'error');
        navigate('/auth/Login');
      }
    } else if (error.request) {
      Toast("An Error occurred", 'warning')
      console.error('No response received:', error.request);
    } else {
      Toast("An Error occurred", 'warning')
      console.error('Error:', error.message);
    }
  };

  const maxRetries = 1;
  const delayMs = 5000;

  const checkAiOutput = async () => {
    try {
      
      setLoader(true)
      const token = localStorage.getItem('accessToken');
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
        Accept: 'application/json',
      };

      const userId = cookies.userID;

      const requestData = {
        'client': userId
      };

      const response = await axios.post('https://creditdecisionbackend.lcfordevelopment.com/api/machinerequests/', requestData, { headers });

      if (response.status === 200) {
        removeCookie('userID', { path: '/' });
        setTimeout(() => {
          Toast('AI Response', 'success');
          setLoader(false)
          setAiResponse(response.data);
          setCertaintly(response.data.certainty)
          setFactors(response.data.factors)
          setRiskScore(response.data.risk_score)
          if (response.data.status === 'True'){
          setAiResponseStatus(true)}
        else {setAiResponseStatus(false)}
        }, 2000);
        setRequestMade(true)
        return true;
      } else {
        setRequestMade(false)
        return false;
      }
    } catch (error) {
      setLoader(false)
      setAiResponse('No response Please Try again in a few minutes');
      if (axios.isAxiosError(error)) {
        handleRequestError(error);
      } else {
        console.error('Non-Axios Error:', error);
        Toast('Reload your page.', 'warning');
      }
      throw error;
    }
  };

  const performAiOutputCheck = async () => {
    for (let retries = 0; retries < maxRetries; retries++) {
      if (!requestMade) {
        try {
          const status200 = await checkAiOutput();

          if (status200) {
            // Status is 200, break the loop

            setRequestMade(true)
            break;
          } else {
            if (retries === maxRetries) {
              Toast('Could not load result', 'warning');
            } else {
              Toast('Network Error. retrying.', 'warning');
            }
          }

        } catch (error) {
          // Handle errors, if needed
        }

        // Wait before the next retry
        await new Promise((resolve) => setTimeout(resolve, delayMs * Math.pow(2, retries)));
        retries++;
        if (retries === maxRetries) {
          Toast('Unknown Error', 'error');
        }
      }
    }
  }

  useEffect(() => {
    performAiOutputCheck();
  }, []);


  // useEffect(() => {

  //   const simulateAsyncOperation = () => {
  //     setTimeout(() => {
  //       setLoader(false);
  //       setAiResponse('Your AI-generated message');
  //     }, 5000);
  //   };

  //   simulateAsyncOperation();
  // }, []);

  const displayFactors = factors? factors.join(', '):'';


  return (
    <Card
      hoverable
      style={{ width: '40%', height: '75%' }}
      bordered={false}
      title="AI Decision"
      type="inner"
      className='output'
    > {loader ?
      <Loader />:
      <>
        <center>
          {aiResponseStatus ? <>
            <CheckCircleOutlined style={{ fontSize: 90, color: 'green', textAlign: 'center' }} />
            <h3><span className='green'>Accepted</span></h3>
          </> : <>
            <CloseCircleOutlined style={{ fontSize: 90, color: 'red', textAlign: 'center' }} />
            <h3><span className='red'>Rejected</span></h3></>}
        </center>
        <h3>Key Factors: </h3><span className='factors'>{displayFactors}</span>
        <h3>Risk Score: </h3>
        <RiskAssessment riskScore={riskScore} />
      </>}
    </Card>
  )
}

export default AiOutput