import React, { useEffect, useState } from 'react';
import { Card, Empty, Tag } from 'antd';
import MyTable from './Table';
import axios, { AxiosError } from 'axios';
import type { ColumnProps } from 'antd/es/table';
import moment from 'moment';
import { Toast } from './Alert';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';

interface DataType {
  first_name: string;
  last_name: string;
  gender: string;
  age: number;
  loan_amount: number;
  ai_status: string[];
  date: Date;

}

const History = () => {
  const [data, setData] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(false)
  const columns: ColumnProps<DataType>[] = [
    {
      title: 'Name',
      dataIndex: 'first_name', 
      key: 'name',
    },
    {
      title: 'Surname',
      dataIndex: 'last_name', 
      key: 'surname',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'Loan',
      dataIndex: 'loan_amount',
      key: 'loan_amount',
    },
    {
      title: 'Machine Response',
      dataIndex: 'ai_status',
      key: 'ai_status',
      render: (aiStatus: boolean) => (
        <Tag color={aiStatus ? 'green' : 'volcano'}>
          {aiStatus ? 'APPROVED' : 'NOT APPROVED'}
        </Tag>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date: Date) => moment(date).format('YYYY-MM-DD'),
    },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {

        const token = localStorage.getItem('accessToken');

        const response = await axios.get('https://creditdecisionbackend.lcfordevelopment.com/api/history/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        });

        console.log(response)
        if (response.data && response.data.data.length > 0) {
          setData(response.data.data);
        } else {
          Toast('No data available.', 'info');
        }
      } catch (error) {
        Toast('Network Error. Please try again.', 'warning');
        if (axios.isAxiosError(error)) {
          handleRequestError(error);
        } else {
          console.error('Non-Axios Error:', error);
          Toast('Reload your page.', 'warning');
        }
      } finally {
        setLoading(false)
      }
    };

    fetchData();
  }, []);

  const handleRequestError = (error: AxiosError) => {

    if (error.response) {
      console.error('Server Error:', error.response.data);
      console.error('Status:', error.response.status);

      if (error.response.status === 400) {
        Toast('Unknow error', 'error');
      }
      if (error.response.status === 401) {
        Toast('Unauthorized', 'error');
        navigate('/auth/Login');
        return;
      }
      if (error.response.status === 403) {
        Toast('Unauthorized', 'error');
        navigate('/auth/Login');
        return;
      }
    } else if (error.request) {
      Toast("An Error occurred", 'warning')
      console.error('No response received:', error.request);
    } else {
      Toast("An Error occurred", 'warning')
      console.error('Error:', error.message);
    }
  };

  return (
    loading ? 
      <Loader /> : 
      <Card
      className="color2"
      hoverable
      style={{ width: '50%', height: '95%', overflow: 'auto' }}
      bordered={true}
      title="Timeline"
      type="inner"
    >
      {data.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <MyTable columns={columns} dataSource={data} />
      )}
    </Card>
  );
};

export default History;
