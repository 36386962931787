import React from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

interface DataType {
  first_name: string;
  last_name: string;
  gender: string;
  age: number;
  loan_amount: number;
  ai_status: string[];
  date: Date;
}

interface MyTableProps {
  dataSource: DataType[];
  columns: ColumnsType<DataType>;
}


const MyTable: React.FC<MyTableProps> = ({ dataSource = [], columns }) =>
  <Table
    columns={columns}
    dataSource={dataSource}
    pagination={{ pageSize: 8, total: dataSource.length }}
    scroll={{ y: 500 }}
    />;

export default MyTable;