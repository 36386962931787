import React, { useState } from 'react';

interface RiskAssessmentProps {
  riskScore: number;
}

const RiskAssessment: React.FC<RiskAssessmentProps> = ({ riskScore }) => {
  const [showScore, setShowScore] = useState(false);

  let riskLevel = 'Low';
  if (riskScore > 50) {
    riskLevel = 'Medium';
  }
  if (riskScore > 75) {
    riskLevel = 'High';
  }

  let color = '#00FF00';
  if (riskLevel === 'Medium') {
    color = '#FFA500';
  } else if (riskLevel === 'High') {
    color = '#FF0000';
  }

  return (
    <div>
      <div
        style={{
          width: '70%',
          border: '1px solid #ccc',
          borderRadius: '5px',
          padding: '5px',
          position: 'relative',
        }}
        onMouseEnter={() => setShowScore(true)}
        onMouseLeave={() => setShowScore(false)}
      >
        <div
          style={{
            width: `${riskScore}%`,
            backgroundColor: color,
            height: '20px',
            borderRadius: '5px',
            transition: 'width 1s ease-in-out',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <span
            style={{
              position: 'absolute',
              top: '-5px',
              left: `${riskScore - 2}%`,
              fontSize: '20px',
              color: '#fff',
            }}
          >
            &gt;&gt;&gt;&gt;&gt;&gt;&gt;&gt;&gt;&gt;&gt;&gt;
          </span>
        </div>
        {showScore && (
          <div
            style={{
              position: 'absolute',
              top: '-35px',
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: '#333',
              color: '#fff',
              padding: '5px',
              borderRadius: '5px',
              zIndex: 999,
            }}
          >
            Risk: {riskScore}%
          </div>
        )}
      </div>
      <p style={{ marginTop: '5px' }}>Risk Level: {riskLevel}</p>
    </div>
  );
};

export default RiskAssessment;