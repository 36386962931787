import React, { useState, useEffect } from 'react';
import TableDash from './TableDash';
import { Layout, Menu, theme } from 'antd';
import Statisti from './Statistic'
import CustomChart from './Chart';
import { Link } from 'react-router-dom';
import { Toast } from '../Alert';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import Settings from './Settings';

const { Header, Sider, Content } = Layout;
interface DataItem {
  machine_answer: true | false;

}
const App: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState('1');
  const [fullScreen, setFullScreen] = useState(false)
  const [data, setData] = useState([])
  const [lineChartData, setLineChartData] = useState([])
  const [pieChartData, setPieChartData] = useState([])
  const [pieChartData2, setPieChartData2] = useState([])
  const {
    token: { colorBgContainer },
  } = theme.useToken();


  const navigate = useNavigate();
  const fetchData = async () => {
    try {

      const token = localStorage.getItem('accessToken');
      if (!token || token === 'undefined') {
        navigate('/auth/Login');
      }

      const response = await axios.post('https://creditdecisionbackend.lcfordevelopment.com/api/admin/list/', {}, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      });

      if (response.status === 200) {
        console.log(response.data)
        setData(response.data.table_data);
        setLineChartData(response.data.line_chart)
        setPieChartData(response.data.pie_chart)
        setPieChartData2(response.data.pie_chart2)
      } else {
        throw new Error(`${response.status}`);
      }
    } catch (error) {
      Toast('Network Error. Please try again.', 'warning');
      if (axios.isAxiosError(error)) {
        handleRequestError(error);
      } else {
        console.error('Non-Axios Error:', error);
        Toast('Reload your page.', 'warning');
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRequestError = (error: AxiosError) => {

    if (error.response) {
      console.error('Server Error:', error.response.data);
      console.error('Status:', error.response.status);

      if (error.response.status === 400) {
        Toast('Unknow error', 'error');
      }
      if (error.response.status === 401) {
        Toast('Unauthorized', 'error');
        navigate('/auth/Login');
        return;
      }
      if (error.response.status === 403) {
        Toast('Unauthorized', 'error');
        navigate('/auth/Login');
        return;
      }
    } else if (error.request) {
      Toast("An Error occurred", 'warning')
      console.error('No response received:', error.request);
    } else {
      Toast("An Error occurred", 'warning')
      console.error('Error:', error.message);
    }
  };




  const handleMenuClick = (key: string) => {
    setSelectedKey(key);
    if (key === '3') {
      navigate('/home');
    }
  };

  let Stats = {
    approuved: 0,
    rejected: 0,
  };

  data && data.forEach((item: DataItem) => {
    if (item.machine_answer === true) {
      Stats.approuved++;
    } else if (item.machine_answer === false) {
      Stats.rejected++;
    }
  });

  const totalCount = Stats.approuved + Stats.rejected;
  Stats.approuved = (Stats.approuved / totalCount) * 100;
  Stats.rejected = (Stats.rejected / totalCount) * 100;



  const handleFullScreen = () => {
    const elem = document.documentElement;

    if (!fullScreen) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
      setFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      setFullScreen(false);
    }
  };



  return (
    <Layout>
      <Sider trigger={null} style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
      }}>
        <div className="demo-logo-vertical">LOGO</div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedKey]}
          onClick={({ key }) => handleMenuClick(key)}
          items={[
            {
              key: '1',
              icon: <i className="fa-solid fa-table-columns"></i>,
              label: 'Dashboard',
            },
            {
              key: '2',
              icon: <i className="fa-solid fa-chart-line"></i>,
              label: 'Charts',
            },
            {
              key: '3',
              icon: <i className="fa-solid fa-home"></i>,
              label: 'Home Page',
            },
            {
              key: '4',
              icon: <i className="fa-solid fa-gear"></i>,
              label: 'Settings',
            }
          ]}
        />
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: 200 }}>
        <Header style={{
          padding: 0,
          background: colorBgContainer,
          position: 'sticky',
          top: 0,
          zIndex: 1,
        }}>
          {/* <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          /> */}

          <div title='Fullscreen' onClick={handleFullScreen} className="action-button-dash info">
            {fullScreen ? <i className="fa-solid fa-compress"></i> : <i className="fa-solid fa-maximize"></i>}
          </div>
          <h2 className='DashHeader'>MANAGER DASHBOARD</h2>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            overflow: 'initial',
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          {selectedKey === '2' ? (
            <CustomChart lineData={lineChartData} pieData={pieChartData} pieData2={pieChartData2} />) : selectedKey === '1' ? (
              <>
                <Statisti data={Stats} />
                <TableDash data={data} />
              </>
            ) : (
            <>
              <Settings />
            </>
          )}

        </Content>
      </Layout>
    </Layout>
  );
};

export default App;