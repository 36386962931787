import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Radio, Button, Card, DatePicker } from 'antd';
import { Toast } from './Alert';
import History from './history';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { Cookies, useCookies } from 'react-cookie';
import Loader from './Loader';

const { Option } = Select;
interface UserRegistrationProps {
    setAiOutputs: () => void;
    timelineBool: boolean;
}

type CombinedProps = UserRegistrationProps

const UserRegistration: React.FC<CombinedProps> = ({ setAiOutputs, timelineBool }) => {
    const [cardPosition, setCardPosition] = useState('colorAuto')
    const { register, setValue } = useForm()
    const [cookies, setCookie] = useCookies(['userID']);
    const [rateData, setRateData] = useState('')
    const [loading, setLoading] = useState(false)
    const [formFields, setFormFields] = useState({
        first_name: false,
        last_name: false,
        age: false,
        gender: false,
        status: false,
        dependents: false,
        education: false,
        property: false,
        appraised_value: false,
        self_employed: false,
        work_experience: false,
        applicant_income: false,
        co_applicant_income: false,
        loan_type: false,
        loan_rate: false,
        loan_history: false,
        loan_amount: false,
        loan_period: false,
        payslip: true,
        utility_payment: true,
        personal: '',
        business: '',
        mortgage: '',
    });


    const navigate = useNavigate();
    const onFinish = async (data: any) => {
        try {
            setLoading(true)
            const token = localStorage.getItem('accessToken');
            if (!token) {
                navigate('/auth/Login');
                return;
            }


            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
                Accept: 'application/json',
            };

            const requestData = data;

            const response = await axios.post('https://creditdecisionbackend.lcfordevelopment.com/api/clients/', requestData, { headers });

            if (response.status === 201) {
                Toast('Analysing user data', 'success');
                const userId = response.data.id;
                setCookie('userID', userId, { path: '/', maxAge: 3600 * 24 * 7 });
                setAiOutputs()
                setCardPosition('color')
            } else {
                console.log('Error', response)
                Toast('An error occured try again', 'error');
            }
        } catch (error) {
            Toast('Network Error. Please try again.', 'warning');
            if (axios.isAxiosError(error)) {
                handleRequestError(error);
            } else {
                console.error('Non-Axios Error:', error);
                Toast('Reload your page.', 'warning');
            }
        } finally{
            setLoading(false)
        }
    }

    const handleRequestError = (error: AxiosError) => {

        if (error.response) {
            console.error('Server Error:', error.response.data);
            console.error('Status:', error.response.status);

            if (error.response.status === 400) {
                Toast('Please  fill out all fields!(Enter 0 if  you don\'t have any experience in this field.)', 'error');
            }
            if (error.response.status === 401) {
                Toast('Unauthorized', 'error');
                navigate('/auth/Login');
                return;
            }
            if (error.response.status === 403) {
                Toast('Unauthorized', 'error');
                navigate('/auth/Login');
                return;
            }
        } else if (error.request) {
            Toast("An Error occurred", 'warning')
            console.error('No response received:', error.request);
        } else {
            Toast("An Error occurred", 'warning')
            console.error('Error:', error.message);
        }
    };

    const [form] = Form.useForm();
    const onReset = () => {
        form.resetFields();
        Toast('Form has been reset!', 'info');
    };


    const [timelineChanged, setTimelineChanged] = useState(false);

    useEffect(() => {
        if (!timelineChanged && timelineBool) {
            setCardPosition('color2');
            setTimelineChanged(true);
        }
    }, [timelineBool, timelineChanged]);

    useEffect(() => {
        const displayForm = async () => {
            setLoading(true)
            try {
                const token = localStorage.getItem('accessToken');
                if (!token) {
                    navigate('/auth/Login')


                    return;
                }


                const headers = {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                    Accept: 'application/json',
                };


                const response = await axios.post('https://creditdecisionbackend.lcfordevelopment.com/api/form_input/', {}, { headers });
 
                if (response.status === 200) {
                    setFormFields(response.data)
                    setRateData(response.data.rate)
                } else if(response.status === 401 || response.status === 403){
                    localStorage.removeItem('accessToken')
                    navigate('/auth/Login')
                }
                else if (response.status === 400){
                    Toast('Please  fill out all fields!(Enter 0 if  you don\'t have any experience in this field.)', 'error');
                }
                else {
                    console.log('Error', response)
                    Toast('An error occured try again', 'error');
                    localStorage.removeItem('accessToken')
                    // Cookies.remove('admin')
                    navigate('/auth/Login')
                }
            } catch (error) {
                Toast('Network Error. Please try again.', 'warning');
                if (axios.isAxiosError(error)) {
                    handleRequestError(error);
                } else {
                    console.error('Non-Axios Error:', error);
                    Toast('Reload your page.', 'warning');
                }
            } finally {
                setLoading(false);
            }
        }
        displayForm()
    }, [])


    const ageOptions = [];
    for (let age = 18; age <= 80; age++) {
        ageOptions.push(
            <Option key={age} value={`${age}`}>{`${age}`}</Option>
        );
    }

    const educationOptions = [
        { label: 'High School', value: 'high_school' },
        { label: 'Bachelor\'s Degree', value: 'bachelors_degree' },
        { label: 'Master\'s Degree', value: 'masters_degree' },
        { label: 'Ph.D.', value: 'phd' },
    ];


    const propertyOptions = [
        { label: 'Magusa', value: 'magusa' },
        { label: 'Lefkosa', value: 'lefkosa' },
        { label: 'Girne', value: 'girne' },
    ];

    const loanOptions = [
        { label: 'Personal', value: 'personal' },
        { label: 'Business', value: 'business' },
        { label: 'Mortgage', value: 'mortage' },
    ];

    const onLoanTypeChange = (value: string) => {

        const selectedRateData = formFields[value as keyof typeof formFields];
        // setRateData(selectedRateData)
        if (selectedRateData !== undefined) {
            setRateData('');
        }
    };


    return (
        <>
            { loading ? 
                    <div
                        className='colorAuto'
                        style={{ width: '50%', height: '86%' }}
                    >
                        <Loader />
                    </div> : timelineBool ? (
                    <History />) :
                    <Form
                            form={form}
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 12 }}
                            layout="horizontal"
                            onFinish={onFinish}
                        >
                    <Card
                        className={cardPosition}
                        hoverable
                        style={{ width: '50%', height: '86%' }}
                        title="Personal Information"
                        bordered={false}
                        type="inner"
                    >
                        
                            {formFields.first_name &&
                                <Form.Item name="first_name" label="Name" rules={[{ required: true, message: 'Please enter client Name' }]}>
                                    <Input placeholder="Enter client name"  {...register('first_name')} />
                                </Form.Item>}

                            {formFields.last_name &&
                                <Form.Item name="last_name" label="Surname" rules={[{ required: true, message: 'Please enter client Surname' }]}>
                                    <Input placeholder="Enter client surname"  {...register('last_name')} />
                                </Form.Item>}

                            {formFields.age &&
                                <Form.Item {...register('age')} label="Age" rules={[{ required: true, message: 'Please select client Age range' }]}>
                                    <Select className='antdInput' placeholder="Select client age"  >
                                        {ageOptions}
                                    </Select>
                                </Form.Item>}

                            {formFields.gender &&
                                <Form.Item  {...register('gender')} label="Gender" rules={[{ required: true, message: 'Please select client Gender' }]}>
                                    <Radio.Group>
                                        <Radio value="male">Male</Radio>
                                        <Radio value="female">Female</Radio>
                                        <Radio value="others">Other</Radio>
                                    </Radio.Group>
                                </Form.Item>}

                            {formFields.status &&
                                <Form.Item  {...register('status')} label="Status" rules={[{ required: true, message: 'Please select client matrimonial Status' }]}>
                                    <Radio.Group>
                                        <Radio value="Married">Married</Radio>
                                        <Radio value="Single">Single</Radio>
                                        <Radio value="Divorced">Divorced</Radio>
                                    </Radio.Group>
                                </Form.Item>}

                            {formFields.dependents &&
                                <Form.Item  {...register('dependents')} label="Dependents" rules={[{ required: false, message: 'Please select client numbers of childs' }]}>
                                    <Select className='antdInput' placeholder="Select Client dependents">
                                        <Select.Option value="0" >0</Select.Option>
                                        <Select.Option value="1" >1</Select.Option>
                                        <Select.Option value="2" >2</Select.Option>
                                        <Select.Option value="3" >3+</Select.Option>
                                    </Select>
                                </Form.Item>}

                            {formFields.self_employed &&
                                <Form.Item  {...register('self_employed')} label="Self employed" rules={[{ required: true, message: 'Please select client employement Status' }]}>
                                    <Radio.Group>
                                        <Radio value="True">Yes</Radio>
                                        <Radio value="False">No</Radio>
                                    </Radio.Group>
                                </Form.Item>}

                            {formFields.work_experience &&
                                <Form.Item  {...register('work_experience')} label="Work Experience" rules={[{ required: true, message: 'Please select client work experience' }]}>
                                    <Select className='antdInput' placeholder="Select Work Experience in years">
                                        <Select.Option value="0" >0</Select.Option>
                                        <Select.Option value="1" >1</Select.Option>
                                        <Select.Option value="2" >2</Select.Option>
                                        <Select.Option value="3" >3</Select.Option>
                                        <Select.Option value="4" >4</Select.Option>
                                        <Select.Option value="5" >5+</Select.Option>
                                    </Select>
                                </Form.Item>}

                            {formFields.education &&
                                <Form.Item {...register('education')} label="Education" rules={[{ required: true, message: 'Please select client Education' }]}>
                                    <Select className='antdInput' placeholder="Select client education">
                                        {educationOptions.map(option => (
                                            <Select.Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>}

                            {formFields.property &&
                                <Form.Item {...register('property_area')} label="Property Area" rules={[{ required: true, message: 'Please enter Property Area' }]}>
                                    <Select className='antdInput' placeholder="Select property location">
                                        {propertyOptions.map(option => (
                                            <Select.Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>}
                            {formFields.appraised_value &&
                                <Form.Item {...register('appraised_value')} label="Appraised value" rules={[{ required: false, message: 'Please enter the appraised value' }]}>
                                    <Input className='antdInput' type="number" placeholder="Enter the appraised value" defaultValue={0} min={0}/>
                                </Form.Item>}
                                </Card><br></br>

                                <Card
                                className={cardPosition}
                                hoverable
                                style={{ width: '50%', height: '86%' }}
                                title="Loan Information"
                                bordered={false}
                                type="inner"
                                >
                            {formFields.applicant_income &&
                                <Form.Item {...register('applicant_income')} label="Applicant Income" rules={[{ required: true, message: 'Please enter Applicant Income' }]}>
                                    <Input className='antdInput' type="number" placeholder="Enter client income" defaultValue={0} min={0} />
                                </Form.Item>}
                            

                            {formFields.co_applicant_income &&
                                <Form.Item {...register('coaplicant_income')} label="Co-Applicant Income" rules={[{ required: false, message: 'Please enter Co-Applicant Income' }]}>
                                    <Input className='antdInput' type="number" placeholder="Enter coapplicant income" defaultValue={0} min={0}/>
                                </Form.Item>}

                            {formFields.loan_type &&
                                <Form.Item {...register('loan_type')} label="Loan Type" rules={[{ required: true, message: 'Please select Loan Type' }]}>
                                    <Select onChange={(value) => onLoanTypeChange(value)} className='antdInput' placeholder="Select Loan Type">
                                        {loanOptions.map(option => (
                                            <Select.Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>}

                            {formFields.loan_rate &&
                                <Form.Item {...register('loan_rate')} label="Loan rate" rules={[{ required: false, message: 'Please enter Loan rate ' }]}>
                                    <Input className='antdInput miniinput' value={rateData || ''} type="number" placeholder=""  min={0}/>
                                </Form.Item>}

                            {formFields.loan_history &&
                                <Form.Item {...register('credit_history')} label="Loan history" rules={[{ required: false, message: 'Please enter Credit History ' }]}>
                                    <Input className='antdInput' type="number" placeholder="Enter client credit history" min={0} defaultValue={0}/>
                                </Form.Item>}

                            {formFields.loan_amount &&
                                <Form.Item {...register('loan_amount')} label="Loan Amount" rules={[{ required: true, message: 'Please enter Credit Amount' }]}>
                                    <Input className='antdInput' type="number" placeholder="Enter credit value" min={0} defaultValue={0} />
                                </Form.Item>}

                            {formFields.loan_period &&
                                <Form.Item
                                    {...register('loan_amount_term', {
                                        required: 'Please enter Loan Period'
                                    })}
                                    label="Loan Period"
                                >
                                    <DatePicker
                                        className='antdInput'
                                        placeholder="Select Loan Period"
                                        onChange={(date, dateString) => {
                                            const formattedDate = moment(dateString).format('YYYY-MM-DD');
                                            setValue('loan_amount_term', formattedDate);
                                        }}
                                    />
                                </Form.Item>}

                            
                            </Card><br></br>

                            {formFields.payslip && formFields.utility_payment ? 
                            <Card
                                className={cardPosition}
                                hoverable
                                style={{ width: '50%', height: '86%' }}
                                title="Additional Informations"
                                bordered={false}
                                type="inner"
                            >
                                {formFields.payslip &&
                                <Form.Item {...register('payslip')} label=" " rules={[{ required: false, message: 'Please upload a document' }]}>
                                    <Input className='antdInput' type="file" placeholder="Payslip" />
                                </Form.Item>}

                                {/* {formFields.utility_payment && */}
                                 {/* <Form.Item {...register('utility_payment')} label="Utility Payment Upload" rules={[{ required: false, message: 'Please upload a document' }]}> */}
                                    {/* <Input className='antdInput' type="file" placeholder="Utility Payment" /> */}
                                {/* </Form.Item>} */}
                            </Card>:null}<br></br>
                            <Card
                                className={cardPosition}
                                hoverable
                                style={{ width: '50%', height: '86%' }}
                                bordered={false}
                                type="inner"
                            >
                            <div className='container'>
                                <Button htmlType='submit' type="primary" >
                                    Proceed
                                </Button>
                                <Button htmlType="button" onClick={onReset} className='transparent' >
                                    Reset
                                </Button>
                            </div>
                            </Card>
                        </Form>
            }
        </>
    );
};

export default UserRegistration;