import React from 'react';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic } from 'antd';

interface StatsProps {
    data: {
      approuved: number;
      rejected: number;
    };
  }

const Statisti: React.FC<StatsProps> = ({data}) => (
  <Row gutter={16}>
    <Col className='border' span={10}>
      <Card bordered>
        <Statistic
          title="Approuved"
          value={data.approuved}
          precision={2}
          valueStyle={{ color: '#3f8600' }}
          prefix={<CheckCircleOutlined />}
          suffix="%"
        />
      </Card>
    </Col>
    <Col className='border' span={10}>
      <Card bordered={false}>
        <Statistic
          title="Rejected"
          value={data.rejected}
          precision={2}
          valueStyle={{ color: '#cf1322' }}
          prefix={<CloseCircleOutlined />}
          suffix="%"
        />
      </Card>
    </Col>
  </Row>
);

export default Statisti;
