import React, { useEffect, useState } from 'react';
import UserRegistration from './UserRegistration';
import AiOutput from './AiOutput';
import Custum from './Custum';
import { useNavigate } from 'react-router-dom';
import AnimatedBackground from './AnimatedBackground';

const Home = () => {
    const [aiOutput, setAiOutput] = useState(false)
    const [timelineBool, setTimelines] = useState(false);
    const Timeline = (value: boolean) => {
        setTimelines(value)
    }
    const navigate = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (!token || token === 'undefined') {
            navigate('/auth/Login');
        }
    }, [navigate]);

    const handleAiOutput = () => {
        if (aiOutput === true) {
            setAiOutput(false)
            setTimeout(() => {
                setAiOutput(true);
            }, 3000);
        } else {
            setAiOutput(true);
        }
    }


    // const loadMachine = async () => {
    //     try {
    //         const token = localStorage.getItem('accessToken');
    //         if (!token) {
    //             navigate('/auth/Login');
    //             return;
    //         }


    //         const headers = {
    //             'Content-Type': 'application/json',
    //             Authorization: `Token ${token}`,
    //             Accept: 'application/json',
    //         };

    //         const userId = cookies.userID;

    //         const requestData = {
    //             'client': userId
    //         };

    //         const response = await axios.post('https://creditdecisionbackend.lcfordevelopment.com/api/machinerequests/', requestData, { headers });
    //         if (response.status === 201) {
    //             setTimeout(() => {
    //                 setAiOutput2(true);
    //                 Toast('Reach intermadiated and continue', 'success');
    //             }, 5000);
    //         }
    //         console.log(response)
    //     } catch (error) {
    //         console.log(error)
    //         if (axios.isAxiosError(error)) {
    //             handleRequestError(error);
    //         } else {
    //             console.error('Non-Axios Error:', error);
    //         }
    //     }
    // }



    // const handleRequestError = (error: AxiosError) => {

    //     if (error.response) {
    //         console.error('Server Error:', error.response.data);
    //         Toast(`${error.response.data}`,'info')
    //         console.error('Status:', error.response.status);

    //         if (error.response.status === 401) {
    //             Toast('Unauthorized', 'error');
    //             navigate('/auth/Login');
    //             return;
    //         }
    //         if (error.response.status === 403) {
    //             Toast('Unauthorized', 'error');
    //             navigate('/auth/Login');
    //             return;
    //         }
    //     } else if (error.request) {
    //         Toast("An Error occurred", 'warning')
    //         console.error('No response received:', error.request);
    //     } else {
    //         Toast("An Error occurred", 'warning')
    //         console.error('Error:', error.message);
    //     }
    // };




    return (
        <div>
            <h1 className="title">Credit Decision AI</h1>
            <UserRegistration setAiOutputs={handleAiOutput} timelineBool={timelineBool} />
            {aiOutput && <AiOutput />}
            <Custum timeline={Timeline} />
            <AnimatedBackground />
        </div>
    )
}

export default Home