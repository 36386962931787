import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import AdminDash from './admin/AdminDash';
import NotFound404 from './NotFound404';
import SignInSide from './auth/Login';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/auth/Login" element={<SignInSide />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/admin/AdminDash" element={<AdminDash />} />
        <Route path="/*" element={<NotFound404 />} />
      </Routes>
    </Router>
  );
}

export default App;
